<template>
  <div class="">
    <el-tabs v-model="activeName" @tab-click="handleClick">
      <el-tab-pane label="密码登陆" name="mima">
        <el-form
          ref="rmimaform"
          :model="rmimaform"
          :rules="passrules"
          class="from-box1"
        >
          <el-form-item prop="phone">
            <el-input
              type="text"
              placeholder="请输入手机号或邮箱"
              v-model="rmimaform.phone"
            />
          </el-form-item>
          <el-form-item prop="pass">
            <el-input
              type="password"
              placeholder="请输入登录密码"
              v-model="rmimaform.pass"
            ></el-input>
          </el-form-item>

          <el-form-item
            ><button class="d-btn-orange" @click.prevent="tologin('pass')">
              登录
            </button></el-form-item
          >
        </el-form>
        <div class="forget font14">
          <span @click="forgetpassword()">忘记密码 </span>|
          <span @click="toregister()">免费注册</span>
        </div>
        <div class="agreement font14" style="color: #00bcff; margin-top: 10px">
          <el-checkbox v-model="checked"></el-checkbox>
          <span>
            已阅读并同意Freemen
            <a
              href="https://freemen.work/UserAgreement.html"
              target="_blank"
              style="text-decoration: none; color: #00bcff"
              >《用户协议》</a
            >和<a
              href="https://freemen.work/PrivacyPolicy.html"
              target="_blank"
              style="text-decoration: none; color: #00bcff"
              >《隐私政策》</a
            ></span
          >
        </div>
        <div class="l-tihsi font16">其他登录方式</div>
        <div class="l-img">
          <img
            src="../../assets/images/login/yuanweixin.png"
            @click="getWxcode"
          />
        </div>
      </el-tab-pane>
      <el-tab-pane label="短信登陆" name="duanxin">
        <el-form
          ref="loginForm"
          :model="rdxform"
          :rules="coderules"
          class="from-box1"
        >
          <el-form-item prop="phone">
            <el-input
              type="text"
              maxlength="11"
              placeholder="手机号"
              v-model="rdxform.phone"
            />
          </el-form-item>
          <el-form-item prop="code">
            <div class="verify-wrapper" style="display: flex">
              <el-input
                type="text"
                maxlength="6"
                placeholder="验证码"
                v-model="rdxform.code"
              ></el-input>
              <el-button
                class="btn-orange"
                :disabled="disabled"
                @click="getCode"
                >{{ valiBtn }}</el-button
              >
            </div>
          </el-form-item>
          <el-form-item
            ><button class="d-btn-orange" @click.prevent="tologin('code')">
              登录
            </button></el-form-item
          >
        </el-form>
        <div class="forget font14">
          <span @click="forgetpassword()">忘记密码 </span>|
          <span @click="toregister()">免费注册</span>
        </div>
        <div class="agreement font14" style="color: #00bcff; margin-top: 10px">
          <el-checkbox v-model="checked"></el-checkbox>
          <span>
            已阅读并同意Freemen
            <a
              href="https://freemen.work/UserAgreement.html"
              target="_blank"
              style="text-decoration: none; color: #00bcff"
              >《用户协议》</a
            >和<a
              href="https://freemen.work/PrivacyPolicy.html"
              target="_blank"
              style="text-decoration: none; color: #00bcff"
              >《隐私政策》</a
            ></span
          >
        </div>
        <div class="l-tihsi font16">其他登录方式</div>
        <div class="l-img">
          <img
            src="../../assets/images/login/yuanweixin.png"
            @click="getWxcode"
          />
        </div>
      </el-tab-pane>
    </el-tabs>
    <el-dialog
      title=""
      :visible.sync="bindPhoneVisible"
      :modal-append-to-body="true"
      :append-to-body="true"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      width="25%"
    >
      <div class="dd">
        <div class="title">绑定手机号</div>
        <el-form
          ref="bindForm"
          :model="bindform"
          :rules="coderules"
          class="from-box1"
        >
          <el-form-item prop="phone">
            <el-input
              type="text"
              maxlength="11"
              placeholder="手机号"
              style="width: 100%"
              v-model="bindform.phone"
            />
          </el-form-item>
          <el-form-item prop="code">
            <div class="verify-wrapper" style="display: flex">
              <el-input
                type="text"
                maxlength="6"
                placeholder="验证码"
                v-model="bindform.code"
              ></el-input>
              <el-button
                class="btn-orange"
                :disabled="disabled"
                @click="getCode1"
                >{{ valiBtn }}</el-button
              >
            </div>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="bindPhoneVisible = false">取 消</el-button>
        <el-button type="primary" @click="toWxLogin">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import Bus from "../../common/bus.js";
export default {
  data() {
    var checkPhone = (rule, value, callback) => {
      const reg =
        /^0?(13[0-9]|15[012356789]|18[0-9]|14[578]|16[6]|17[035768]|19[19])[0-9]{8}$/;
      if (reg.test(value)) {
        return callback();
      }
      callback("请输入合法的手机号");
    };
    return {
      checked: true,
      // recruittype:'',
      activeName: "mima",
      rmimaform: {
        phone: "",
        pass: "",
      },
      passrules: {
        phone: [
          { required: true, message: "请输入手机号或邮箱", trigger: "blur" },
        ],
        pass: [{ required: true, message: "请输入密码", trigger: "blur" }],
      },
      coderules: {
        phone: [{ validator: checkPhone, required: true, trigger: "blur" }],
        code: [{ required: true, message: "请输入验证码", trigger: "blur" }],
      },
      rdxform: {
        phone: "",
        code: "",
      },
      valiBtn: "获取验证码",
      disabled: false,
      forgetshow: false,
      // 测试
      loginForm: {
        username: "admin",
        password: "123456",
      },
      wxConfig: {},
      bindform: {
        phone: "",
        code: "",
      },
      bindPhoneVisible: false,
    };
  },
  created() {
    if (sessionStorage.getItem("wxCode")) {
      let wxCode = JSON.parse(sessionStorage.getItem("wxCode"));
      sessionStorage.removeItem("wxCode");
      this.$api.wxCallback("post", { code: wxCode, flag: 2 }).then((res) => {
        let wxConfig = {
          avatar: res.data.avatar ? res.data.avatar : "",
          code: res.data.code ? res.data.code : "",
          displayName: res.data.displayName ? res.data.displayName : "",
          inviteCode: res.data.inviteCode ? res.data.inviteCode : "",
          openId: res.data.openId ? res.data.openId : "",
          phone: res.data.phone ? res.data.phone : "",
          registrationId: res.data.registrationId
            ? res.data.registrationId
            : "",
          unionId: res.data.unionId ? res.data.unionId : "",
        };
        this.wxConfig = wxConfig;

        if (res.code == 10603) {
          // this.$message.error("微信登录没有绑定过用户");
          this.bindPhoneVisible = true;
          return;
        }
        this.loginSuccess(res);
      });
    }
  },
  methods: {
    getWxcode() {
      if (!this.checked) {
        this.$message({
          message: "请先同意用户协议和隐私政策",
          type: "error",
        });
        return;
      }
      this.$wxPoput.show();
    },
    toWxLogin() {
      this.bindPhoneVisible = false;
      this.$refs["bindForm"].validateField("phone", (err) => {
        if (err) {
          return;
        } else {
          this.wxConfig["code"] = this.bindform.code;
          this.wxConfig["phone"] = this.bindform.phone;
          this.$api.wxLogin("post", this.wxConfig).then((res) => {
            if (res.code == 10200) {
              this.loginSuccess(res);
            } else {
              this.$message.error(res.message);
            }
          });
        }
      });
    },
    toregister() {
      this.$emit("register", "20");
    },
    forgetpassword() {
      // console.log("wangji");
      Bus.$emit("forgetpsdstuts", true);
    },
    handleClick() {
      // console.log(tab, event);
    },

    getCode1() {
      this.$refs["bindForm"].validateField("phone", (err) => {
        if (err) {
          return;
        } else {
          this.tackBtn(); //验证码倒数60秒
          let fd = {
            phone: this.bindform.phone,
            type: 2,
          };
          this.$api.phonemsg("post", fd).then((res) => {
            // console.log(res);
            if (res.code != 10200) {
              this.$message.error(res.message);
            }
          });
        }
      });
    },
    //获取验证码 并只验证手机号 是否正确
    getCode() {
      this.$refs["loginForm"].validateField("phone", (err) => {
        if (err) {
          return;
        } else {
          this.tackBtn(); //验证码倒数60秒
          let fd = {
            phone: this.rdxform.phone,
            type: 2,
          };
          this.$api.phonemsg("post", fd).then((res) => {
            // console.log(res);
            if (res.code != 10200) {
              this.$message.error(res.message);
            }
          });
        }
      });
    },
    tackBtn() {
      //验证码倒数60秒
      let time = 60;
      let timer = setInterval(() => {
        if (time == 0) {
          clearInterval(timer);
          this.valiBtn = "获取验证码";
          this.disabled = false;
        } else {
          this.disabled = true;
          this.valiBtn = time + "秒后重发";
          time--;
        }
      }, 1000);
    },
    tologin(type) {
      if (type == "code") {
        this.$refs.loginForm.validate((valid) => {
          if (valid) {
            let loginConfig = {
              phone: this.rdxform.phone,
              code: this.rdxform.code,
            };
            this.$api.codelogin("post", loginConfig).then((res) => {
              if (res.code != 10200) {
                if (res.code == 10605) {
                  this.$message.error("账号未注册，请先注册");
                } else {
                  this.$message.error(res.message);
                }
                return;
              }
              window.sessionStorage.setItem("token", res.data.token);
              window.sessionStorage.setItem(
                "userInfo",
                JSON.stringify(res.data)
              );
              if (res.code == 10605) {
                this.$router.push("/realnames");
              } else if (res.code == 10200) {
                if (res.data.companyIdStatus == 0) {
                  //未实名
                  this.$router.push("/realnames");
                } else if (res.data.companyPayStatus == 0) {
                  //未打款
                  this.$router.push("/realnames");
                } else {
                  this.loginSuccess(res);
                }
              }
            });
          } else {
            return false;
          }
        });
      } else {
        this.$refs.rmimaform.validate((valid) => {
          if (valid) {
            let loginConfig = {
              code: "",
              loginEmail: this.rmimaform.phone,
              password: this.rmimaform.pass,
              registrationId: "",
            };
            this.$api.passlogin("post", loginConfig).then((res) => {
              if (res.code != 10200) {
                if (res.code == 10605) {
                  this.$message.error("账号未注册，请先注册");
                } else {
                  this.$message.error(res.message);
                }

                // this.$router.push({
                //   path: "/login",
                // });
                return;
              }
              window.sessionStorage.setItem("token", res.data.token);
              window.sessionStorage.setItem(
                "userInfo",
                JSON.stringify(res.data)
              );
              if (res.code == 10605) {
                this.$router.push("/realnames");
              } else if (res.code == 10200) {
                if (res.data.companyIdStatus == 0) {
                  //未实名
                  this.$router.push("/realnames");
                } else if (res.data.companyPayStatus == 0) {
                  //未打款
                  this.$router.push("/realnames");
                } else {
                  this.loginSuccess(res);
                }
              }
            });
          } else {
            return false;
          }
        });
      }
      // this.$router.push('/realnames');
      // this.$http.post('login', this.loginForm).then(res=>{
      // 	// console.log(res)
      // 	if (res.data.meta.status == 200) {
      // 		this.$message({
      // 			message: '登录成功',
      // 			type: 'success'
      // 		});
      // 		window.sessionStorage.setItem('token', res.data.data.token);
      // 		this.$router.push('/realnames');
      // 	} else {
      // 		console.log('89098909878')
      // 		window.sessionStorage.setItem('token', 'hhjbsajdbkjasbdjkfbasjk');
      // 		this.$router.push('/realnames');
      // 		this.$message({
      // 			message: res.data.meta.msg,
      // 			type: 'error'
      // 		});
      // 	}
      // })
    },
    loginSuccess(res) {
      //登录成功后
      let userInfo = res.data;
      window.sessionStorage.setItem("token", res.data.token);
      window.sessionStorage.setItem("userInfo", JSON.stringify(res.data));
      this.getExpyear();
      this.getSkill();
      this.getEducationType();
      this.getCompanywelfare();
      this.getCompanytype();
      this.getLanguageType();
      this.$IM.login({
        id: res.data.id,
        error: (err) => {
          let that = this;
          if (err.data.type == 17) {
            that.$IM.register({
              id: res.data.id,
              success: () => {
                that.$IM.login({
                  id: res.data.id,
                  success: () => {
                    window.IMlogin = true;
                  },
                });
              },
            });
          }
        },
        success: () => {
          window.IMlogin = true;
        },
      });
      if (userInfo.isAdmin == 1 && userInfo.status != 1) {
        this.$router.push("/realnames");
      } else {
        this.$router.push("/induction");
      }
    },
    getExpyear() {
      //获取工作经验
      this.$api.getdict("get", { code: "exp_year" }).then((res) => {
        let list = [];
        res.data.map((item) => {
          let obj = {
            value: item.id,
            label: item.name,
          };
          list.push(obj);
        });
        window.sessionStorage.setItem("expYear", JSON.stringify(list));
      });
    },
    getSkill() {
      //获取技能标签
      let skills = {
        itSkills: [],
        chipSkills: [],
      };
      let flag = false;
      this.$api.getSkillList("get", {code: "skill"}).then((res1) => {
        if (res1.data === null || res1.data.length === 0) {
          flag = true;
        }
        // console.log("----getSkill-----$api.getSkillList-----skill--- result = ", res1.data);
        skills.itSkills = res1.data;
        this.$api.getSkillList("get", {code: "chip_skill"}).then((res2) => {
          if (res2.data === null || res2.data.length === 0) {
            flag = true;
          }
          // console.log("----getSkill-----$api.getSkillList-----chip_skill--- result = ", res2.data);
          skills.chipSkills = res2.data;
          if(flag) {
            this.$message({
              message: "获取技能列表失败！ "+ res2.message,
              type: "error",
            });
            return;
          }
          // console.log("----getSkill----- skills.result = ", skills);
          window.sessionStorage.setItem("skill", JSON.stringify(skills));
        });
      });
    },
    getEducationType() {
      //获取学历
      this.$api.getdict("get", { code: "education_type" }).then((res) => {
        let list = [];
        if(res.data !== null && res.data.length > 0){
          let tempflag = false;
          res.data.map((item) => {
            if(item.name === '不限'){
              tempflag = true;
            }
            let obj = {
              value: item.id,
              label: item.name,
            };
            list.push(obj);
          });
          if(!tempflag){
            list.push({ value: "526", label: "不限",});
          }
        } else {
          let obj1 = {
            value: "526",
            label: "不限",
          };
          list.push(obj1);
        }
        window.sessionStorage.setItem("educationType", JSON.stringify(list));
      });
    },
    getCompanywelfare() {
      //获取公司福利
      this.$api.getdict("get", { code: "company_welfare" }).then((res) => {
        let list = [];
        res.data.map((item) => {
          let obj = {
            value: item.id,
            label: item.name,
          };
          list.push(obj);
        });
        window.sessionStorage.setItem("companyWelfare", JSON.stringify(list));
      });
    },
    getCompanytype() {
      //获取企业类型
      this.$api.getdict("get", { code: "company_type" }).then((res) => {
        let list = [];
        res.data.map((item) => {
          let obj = {
            value: item.id,
            label: item.name,
          };
          list.push(obj);
        });
        window.sessionStorage.setItem("companyType", JSON.stringify(list));
      });
    },
    getScoreoption() {
      //获取打分项
      this.$api.getdict("get", { code: "score_option" }).then((res) => {
        let list = [];
        res.data.map((item) => {
          let obj = {
            value: item.id,
            label: item.name,
          };
          list.push(obj);
        });
        window.sessionStorage.setItem("scoreOption", JSON.stringify(list));
      });
    },
    getLanguageType() {
      //获取语言类型
      this.$api.getdict("get", { code: "language_type" }).then((res) => {
        // console.log("----getLanguageType-----$api.getdict-----language_type--- result = ", res);
        window.sessionStorage.setItem("languageType", JSON.stringify(res.data));
      });
    },
  },
};
</script>
<style>
.el-tabs__nav-wrap::after {
  background-color: #fff !important;
}
.el-tabs__nav-scroll {
  display: flex;
  align-items: center;
  justify-content: center;
}
.el-tabs__item.is-active {
  color: #303133;
}
</style>
<style lang="less" scoped>
.from-box1 {
  /deep/ .el-input__inner {
    border-radius: 0px;
  }
  /deep/ .el-button {
    border-radius: 0px;
  }

  .btn-orange {
    margin-left: 10px;
  }
  .d-btn-orange {
    width: 100%;
    height: 44px;
    background-color: #00bcff;
    border: none;
    color: #ffffff;
  }
}
.forget {
  color: #666666;
  span:hover {
    cursor: pointer;
  }
}
.l-tihsi {
  color: #111111;
  text-align: center;
  margin-top: 30px;
}
.l-img {
  text-align: center;
  margin-top: 20px;
  img:hover {
    cursor: pointer;
  }
  img {
    width: 64px;
    height: 64px;
  }
}

.dd {
  padding: 20px !important;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  .title {
    margin-bottom: 20px;
    font-size: 25px;
  }
}
</style>
