<template>
	<div class="">
		<div class="tit font14">首次验证登录即注册</div>
		<el-form ref="form" :model="form" :rules="rules"  class="from-box1">
			<el-form-item  prop="phone">
				<el-input type="text" maxlength="11"  placeholder="手机号" v-model="form.phone" />
			</el-form-item>
			<el-form-item prop="code">
				<div class="verify-wrapper" style="display: flex;">
					<el-input type="text" maxlength="6" placeholder="验证码" v-model="form.code" ></el-input>
					<el-button class="btn-orange" :disabled="disabled" @click="getCode">{{ valiBtn }}</el-button>
				</div>
			</el-form-item>
			<el-form-item>
				<el-checkbox v-model="xieyichecked">已阅读同意<span style="color: #409EFF;">《Freeman用户服务协议》</span></el-checkbox>
			</el-form-item>
			<el-form-item><button class="d-btn-orange" @click="toshiming()">立即注册</button></el-form-item>
		</el-form>
		<div class="l-tishi font14" @click.prevent="onSubmit('form')">
			已有账号？<span>快速登录>></span>
		</div>
	</div>
</template>

<script>
	export default {
		data(){
			var checkPhone = (rule, value, callback) => {
				const reg = /^0?(13[0-9]|15[012356789]|18[0-9]|14[578]|16[6]|17[035768]|19[19])[0-9]{8}$/;
				if (reg.test(value)) {
					return callback();
				}
				callback('请输入合法的手机号');
			};
			return{
				// 测试
				loginForm:{
					username:'admin',
					password:'123456'
				},
				
				// 正式
				valiBtn: '获取验证码',
				disabled: false,
				xieyichecked: false,//协议选择
				autoLogin: false,
				form: {
					phone: '',
					code: ''
				},
				rules: {
					phone: [{ validator: checkPhone, required: true, trigger: 'blur' }],
					code: [{ required: true, message: '请输入验证码', trigger: 'blur' }]
				},
			}
		},
		methods:{
			//获取验证码 并只验证手机号 是否正确
			getCode() {
				this.$refs['form'].validateField('phone', err => {
					if (err) {
						console.log('未通过');
						return;
					} else {
						console.log('已通过');
						this.tackBtn(); //验证码倒数60秒
						let fd = new FormData(); //POST 请求需要 转化为Form
						fd.append('PhoneNumber', this.form.phone);
						fd.append('NeedCheck', 2);
						// this.$axios({
						// 	method: 'POST',
						// 	data: fd,
						// 	url: '/api/sgsaccount/vcodeget'
						// }).then(res => {
						// 	console.log(res);
						// });
					}
				});
			},
			tackBtn() {
				//验证码倒数60秒
				let time = 60;
				let timer = setInterval(() => {
					if (time == 0) {
						clearInterval(timer);
						this.valiBtn = '获取验证码';
						this.disabled = false;
					} else {
						this.disabled = true;
						this.valiBtn = time + '秒后重发';
						time--;
					}
				}, 1000);
			},
			toshiming(){
				this.$router.push('/realnames');
			},
			onSubmit(formName) {
				//点击登录 验证手机& 验证码是否符合条件
				this.$refs[formName].validate(valid => {
					// 为表单绑定验证功能
					if (valid) {
						console.log('正确登陆')
					} else {
						// this.dialogVisible = true;
						return false;
					}
				});
				// this.$router.push('/realnames');
				// this.$http.post('login', this.loginForm).then(res=>{
				// 	// console.log(res)
				// 	if (res.data.meta.status == 200) {
				// 		this.$message({
				// 			message: '登录成功',
				// 			type: 'success'
				// 		});
				// 		window.sessionStorage.setItem('token', res.data.data.token);
				// 		this.$router.push('/realnames');
				// 	} else {
				// 		console.log('89098909878')
				// 		window.sessionStorage.setItem('token', 'hhjbsajdbkjasbdjkfbasjk');
				// 		this.$router.push('/realnames');
				// 		this.$message({
				// 			message: res.data.meta.msg,
				// 			type: 'error'
				// 		});
				// 	}
				// })
			}
		}
	}
	
	
</script>

<style scoped lang="less">
	.interview{
		margin-top: 30px;
		.tit{
			text-align: center;
			line-height: 3;
			color: #999999;
			font-weight: 600;
		}
		.l-tishi:hover{
			cursor: pointer;
		}
		.l-tishi{
			text-align: center;
			color: #999999;
			font-weight: 600;
			span{
				color: #00BCFF;
			}
		}
	}
	.from-box1{
		
		.btn-orange{
			margin-left: 10px;
		}
		.d-btn-orange{
			width: 100%;
			height: 44px;
			background-color: #00BCFF;
			border: none;
			color: #FFFFFF;
		}
	}
</style>
