<template>
  <div class="login_container">
    <div class="login_box" :style="isEmail ? 'height:720px' : '580px'">
      <div class="left-content font16">
        <div class="entry e-active">
          <img src="../assets/images/login/left_zhiwei.png" />
          <div class="entry-info">
            <div class="">职位</div>
            <div class="font14">入职or驻场任你挑</div>
          </div>
        </div>
        <div class="entry">
          <img src="../assets/images/login/left_toudi.png" />
          <div class="entry-info">
            <div class="">投递</div>
            <div class="font14">快速投递直达HR</div>
          </div>
        </div>
        <div class="entry">
          <img src="../assets/images/login/left_goutong.png" />
          <div class="entry-info">
            <div class="">沟通</div>
            <div class="font14">握手职位即时沟通</div>
          </div>
        </div>
      </div>
      <div class="right-content" v-if="codestuts">
        <div class="tab-list font16">
          <div :class="tabnum == 1 ? 'tl-active' : ''" @click="choosetab(1)">
            我要应聘
          </div>
          <div
            :class="tabnum == 2 || tabnum == 20 ? 'tl-active' : ''"
            @click="choosetab(2)"
          >
            我要招人
          </div>
        </div>
        <!-- 我要应聘部分 -->
        <div class="interview" v-if="tabnum == 1">
          <!-- 我要应聘的登录即注册 -->
          <interview></interview>
          <!--  -->
        </div>
        <!-- 我要招人部分 -->
        <div class="recruit" v-if="tabnum == 2 || tabnum == 20">
          <!-- 我要招人的登录 -->
          <recruitlogin
            @register="changeType"
            v-if="tabnum == 2"
          ></recruitlogin>
          <!-- 我要招人的注册 -->
          <recruitregister
            @fastlogin="changeType"
            @changeTab="changeTab"
            v-if="tabnum == 20"
          ></recruitregister>
        </div>
      </div>
      <div class="saocode" v-if="!codestuts">
        <scancode></scancode>
      </div>
      <!-- 扫码登录 -->
      <div class="topright" v-if="codestuts" @click="codesao()">
        <div>
          {{ codestuts ? "扫码登录" : "切换密码或短信登录" }}
        </div>
      </div>
      <div class="toprightelse" v-if="!codestuts">
        <!-- <div >{{ ?'扫码登录':''}}</div> -->
        <span @click="codesao()">切换密码或短信登录</span>
      </div>
    </div>
    <forgetpassword></forgetpassword>
  </div>
</template>

<script>
import forgetpassword from "/src/components/logines/forgetpassword.vue"; //我要应聘的登录即注册
import interview from "/src/components/logines/interview.vue"; //我要应聘的登录即注册
import recruitlogin from "/src/components/logines/recruitlogin.vue"; //我要招人的登录
import recruitregister from "/src/components/logines/recruitregister.vue"; //我要招人的注册
import scancode from "/src/components/logines/scancode.vue"; //我要招人的注册
export default {
  components: {
    interview,
    recruitlogin,
    recruitregister,
    scancode,
    forgetpassword,
  },
  data() {
    return {
      tabnum: 2, //应聘和招人切换 1，应聘 2，招人, 20，招人的注册
      codestuts: true,
      wjshow: false,
      isEmail: false,
    };
  },
  methods: {
    codesao() {
      this.codestuts = !this.codestuts;
    },
    changeTab(e) {
      // console.log("----- Login.changeTab(e), e = ", e);
      this.isEmail = e == 2;
    },
    choosetab(numb) {
      if (numb == 1) {
        window.location.href = "https://job.freemen.work/#/login";  // "http://192.168.10.174:8080/#/login";
        return;
      } else {
        this.tabnum = numb;
      }
    },
    changeType(nums) {
      this.tabnum = nums;
      // console.log("获取组件的值", this.tabnum);
    },
    // 老登陆方法
    login() {
      this.$refs["LoginFormRef"].validate(async (valid) => {
        if (valid) {
          const { data: res } = await this.$http.post("login", this.loginForm);
          if (res.meta.status == 200) {
            this.$message({
              message: "登录成功",
              type: "success",
            });
            window.sessionStorage.setItem("token", res.data.token);
            this.$router.push("/merchant");
          } else {
            this.$message({
              message: res.meta.msg,
              type: "error",
            });
          }
        } else {
          return false;
        }
      });
    },
  },
};
</script>
<style>
.el-tabs__nav-wrap::after {
  background-color: #fff !important;
}
.el-tabs__nav-scroll {
  display: flex;
  align-items: center;
  justify-content: center;
}
.el-tabs__item.is-active {
  color: #303133;
}
</style>
<style lang="less" scoped>
.login_container {
  height: 100%;
  background: url("https://seopic.699pic.com/photo/40099/9783.jpg_wh1200.jpg")
    no-repeat left top;
  background-size: 100% 100%;
}

.loggin_box {
  width: 450px;
  height: 300px;
  background-color: #fff;
  border-radius: 3px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

// .avatar_box{
//   width: 130px;
//   height: 130px;
//   border: 1px solid #eee;
//   border-radius: 50%;
//   padding: 10px;
//   box-shadow: 0 0 10px #ddd;
//   position: absolute;
//   left:50%;
//   transform: translate(-50%,-50%);
//   background-color: #fff;
//   img{
//     width: 100%;
//     height: 100%;
//     border-radius: 50%;
//     background-color: #eee;
//   }
// }
// .login_form {
// 	position: absolute;
// 	bottom: 0;
// 	width: 100%;
// 	padding: 20px;
// 	box-sizing: border-box;
// }

// .btns {
// 	display: flex;
// 	justify-content: flex-end;
// }

.login_box {
  width: 880px;
  height: 580px;
  background-color: #ffffff;
  border-radius: 8px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  display: flex;

  .left-content {
    width: 350px;
    height: 100%;
    background-color: #d4f0fa;
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    .entry {
      display: flex;
      align-items: center;
      background-color: #ffffff;
      border-radius: 8px;
      margin-top: 20px;
      padding: 0 30px 0 10px;
      img {
        width: 100px;
        height: 100px;
        padding-top: 10px;
      }
      .entry-info {
        line-height: 2;
        color: #333333;
        div:last-child {
          color: #999999;
        }
      }
    }
    .entry:nth-of-type(1) {
      margin-top: 0px;
    }
    .e-active {
      .entry-info {
        line-height: 2;
        color: #00bcff !important;
        div:last-child {
          color: #00bcff !important;
        }
      }
    }
  }
  .saocode {
    display: flex;
    flex: 1;
  }
  .right-content {
    // text-align: center;
    // justify-content: center;
    position: relative;
    flex: 1;
    flex-direction: column;
    padding: 40px 70px;
    .tab-list {
      display: flex;
      align-items: center;
      justify-content: center;
      div:hover {
        cursor: pointer;
      }
      div {
        border: 1px solid #dddddd;
        display: flex;
        align-items: center;
        justify-content: center;
        flex: 1;
        padding: 6px 0;
      }
      div:first-child {
        margin-right: 10px;
      }
      div:last-child {
        margin-left: 10px;
      }
      .tl-active {
        border: 1px solid #00bcff;
        color: #00bcff;
      }
    }
    .interview {
      margin-top: 30px;
      .tit {
        text-align: center;
        line-height: 3;
        color: #999999;
        font-weight: 600;
      }
      .l-tishi {
        text-align: center;
        color: #999999;
        font-weight: 600;
        span {
          color: #00bcff;
        }
      }
    }
    .forget {
      color: #666666;
    }
    .l-tihsi {
      color: #111111;
      text-align: center;
      margin-top: 30px;
    }
    .l-img {
      text-align: center;
      margin-top: 10px;
      img {
        width: 64px;
        height: 64px;
      }
    }
    .from-box1 {
      /deep/ .el-input__inner {
        border-radius: 0px;
      }
      /deep/ .el-button {
        border-radius: 0px;
      }

      .btn-orange {
        margin-left: 10px;
      }
      .d-btn-orange {
        width: 100%;
        height: 44px;
        background-color: #00bcff;
        border: none;
        color: #ffffff;
      }
    }
  }
}

.recruit {
  padding-top: 30px;
}
.topright:hover {
  cursor: pointer;
}
.topright {
  position: absolute;
  top: 0px;
  right: 0px;
  background: url(../assets/images/login/saomal.png) no-repeat;
  background-size: 100% 100%;
  width: 56px;
  height: 50px;
  div {
    font-size: 14px;
    transform: rotate(45deg) translate(15px, 25px);
  }
}
.toprightelse:hover {
  cursor: pointer;
}
.toprightelse {
  position: absolute;
  top: 20px;
  right: 30px;
  span {
    font-size: 14px;
  }
}
</style>

