<template>
  <div class="code-box">
    <div class="tit font22">手机APP扫码安全登录</div>
    <div class="shixiao" v-if="outTime">
      <div class="s-tihsi">二维码失效</div>
      <div class="s-btn" @click="getQrcode">
        <span>刷新二维码</span>
      </div>
    </div>
    <div class="code_img" v-else>
      <canvas id="canvas"></canvas>
      <!-- <img src="../../assets/images/login/code-pic.png" > -->
    </div>
    <div class="ts_text font14">
      <img src="../../assets/images/login/saocode.png" />
      打开FreemanAPP 扫一扫登录
    </div>
    <!-- <div class="other font16">其他登录方式</div>
    <div class="weixin">
      <img src="../../assets/images/login/yuanweixin.png" @click="getWxcode" />
    </div> -->
  </div>
</template>

<script>
export default {
  data() {
    return {
      outTime: false,
      outTimer: null,
    };
  },
  created() {
    this.getQrcode();
  },
  destroyed() {
    clearInterval(this.outTimer);
  },
  methods: {
    getWxcode() {
      if (!this.checked) {
        this.$message({
          message: "请先同意用户协议和隐私政策",
          type: "error",
        });
        return;
      }
      this.$wxPoput.show();
    },
    loginSuccess(res) {
      //登录成功后
      let userInfo = res.data;
      window.sessionStorage.setItem("token", res.data.token);
      window.sessionStorage.setItem("userInfo", JSON.stringify(res.data));
      this.getExpyear();
      this.getSkill();
      this.getEducationType();
      this.getCompanywelfare();
      this.getCompanytype();
      this.getLanguageType();
      this.$IM.login({
        id: res.data.id,
        error: (err) => {
          let that = this;
          if (err.data.type == 17) {
            that.$IM.register({
              id: res.data.id,
              success: () => {
                that.$IM.login({
                  id: res.data.id,
                  success: () => {
                    window.IMlogin = true;
                  },
                });
              },
            });
          }
        },
        success: () => {
          window.IMlogin = true;
        },
      });
      if (userInfo.isAdmin == 1 && userInfo.status != 1) {
        this.$router.push("/realnames");
      } else {
        this.$router.push("/induction");
      }
    },
    getExpyear() {
      //获取工作经验
      this.$api.getdict("get", { code: "exp_year" }).then((res) => {
        let list = [];
        res.data.map((item) => {
          let obj = {
            value: item.id,
            label: item.name,
          };
          list.push(obj);
        });
        window.sessionStorage.setItem("expYear", JSON.stringify(list));
      });
    },
    getSkill() {
      //获取技能标签
      // this.$api.getdict("get", { code: "skill" }).then((res) => {
      //   window.sessionStorage.setItem("skill", JSON.stringify(res.data));
      // });
      let skills = {
        itSkills: [],
        chipSkills: [],
      };
      let flag = false;
      this.$api.getSkillList("get", {code: "skill"}).then((res1) => {
        if (res1.data === null || res1.data.length === 0) {
          flag = true;
        }
        // console.log("----getSkill-----$api.getSkillList-----skill--- result = ", res1.data);
        skills.itSkills = res1.data;
        this.$api.getSkillList("get", {code: "chip_skill"}).then((res2) => {
          if (res2.data === null || res2.data.length === 0) {
            flag = true;
          }
          // console.log("----getSkill-----$api.getSkillList-----chip_skill--- result = ", res2.data);
          skills.chipSkills = res2.data;
          if(flag) {
            this.$message({
              message: "获取技能列表失败！ "+ res2.message,
              type: "error",
            });
            return;
          }
          window.sessionStorage.setItem("skill", JSON.stringify(skills));
        });
      });
    },
    getEducationType() {
      //获取学历
      this.$api.getdict("get", { code: "education_type" }).then((res) => {
        let list = [];
        if(res.data !== null && res.data.length > 0){
          let tmpFlag = false;
          // console.log("通过api获取学历选项 $api.getdict.res = ", res.data);
          res.data.map((item) => {
            if(item.name === '不限'){
              tmpFlag = true;
            }
            let obj = {
              value: item.id,
              label: item.name,
            };
            list.push(obj);
          });
          if(!tmpFlag){
            list.push({ value: "526", label: "不限",});
          }
        } else {
          let obj1 = {
            value: "526",
            label: "不限",
          };
          list.push(obj1);
        }
        window.sessionStorage.setItem("educationType", JSON.stringify(list));
      });
    },
    getCompanywelfare() {
      //获取公司福利
      this.$api.getdict("get", { code: "company_welfare" }).then((res) => {
        let list = [];
        res.data.map((item) => {
          let obj = {
            value: item.id,
            label: item.name,
          };
          list.push(obj);
        });
        window.sessionStorage.setItem("companyWelfare", JSON.stringify(list));
      });
    },
    getCompanytype() {
      //获取企业类型
      this.$api.getdict("get", { code: "company_type" }).then((res) => {
        let list = [];
        res.data.map((item) => {
          let obj = {
            value: item.id,
            label: item.name,
          };
          list.push(obj);
        });
        window.sessionStorage.setItem("companyType", JSON.stringify(list));
      });
    },
    getScoreoption() {
      //获取打分项
      this.$api.getdict("get", { code: "score_option" }).then((res) => {
        let list = [];
        res.data.map((item) => {
          let obj = {
            value: item.id,
            label: item.name,
          };
          list.push(obj);
        });
        window.sessionStorage.setItem("scoreOption", JSON.stringify(list));
      });
    },
    getLanguageType() {
      //获取语言类型
      this.$api.getdict("get", { code: "language_type" }).then((res) => {
        window.sessionStorage.setItem("languageType", JSON.stringify(res.data));
      });
    },
    getQrcode() {
      this.outTime = false;

      this.$api.getqrcode("get").then((res) => {
        // this.$message({
        //   message: "二维码获取成功",
        //   type: "success",
        // });
        let timer = setTimeout(() => {
          var QRCode = require("qrcode");
          var canvas = document.getElementById("canvas");
          QRCode.toCanvas(canvas, res.data, (error) => {
            if (error) console.error(error);
            clearTimeout(timer);
          });
        }, 20);
        let time = 0;
        let uuid = res.data.replace(/freemen.work:login:code:/g, "");

        this.outTimer = setInterval(() => {
          time++;
          // console.log("scan code to login, setInterval----------- time count:", time);
          this.$api
            .getToken("get", {
              uuid: uuid,
            })
            .then((r) => {
              // console.log("scan ####  getToken.res = ", r);
              if (r.code == 10200 && r.data !== uuid) {  // r.data.indexOf("company_") != -1
                this.$message({
                  message: "登录成功",
                  type: "success",
                });
                window.sessionStorage.setItem(
                  "token",
                  r.data.replace(/company_/g, "")
                );
                this.$api.getDetail("get").then((rr) => {
                  window.sessionStorage.setItem(
                    "userInfo",
                    JSON.stringify(rr.data)
                  );
                  if (rr.code == 10605) {
                    this.$router.push("/realnames");
                  } else if (rr.code == 10200) {
                    if (rr.data.companyIdStatus == 0) {
                      //未实名
                      this.$router.push("/realnames");
                    } else if (rr.data.companyPayStatus == 0) {
                      //未打款
                      this.$router.push("/realnames");
                    } else {
                      this.loginSuccess(rr);
                    }
                  }
                });

                clearInterval(this.outTimer);
              }
            });
          if (time >= 300) {
            this.outTime = true;
            clearInterval(this.outTimer);
          }
        }, 1500);
      });
    },
  },
};
</script>

<style lang="less" scoped>
.code-box {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  line-height: 3;
  .tit {
    text-align: center;
    color: #111111;
    font-weight: 600;
  }
  .code_img {
    text-align: center;
    img {
      width: 220px;
      height: 220px;
      // border: 1px solid #000000;
    }
  }
  .shixiao {
    width: 220px;
    height: 200px;
    background-color: #f3f3f3;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    line-height: 4;
    margin-bottom: 20px;
    .s-tihsi {
      color: #999999;
      font-size: 14px;
    }
    .s-btn:hover {
      cursor: pointer;
    }
    .s-btn {
      span {
        color: #ffffff;
        font-size: 14px;
        padding: 13px 20px;
        background-color: #00bcff;
        border-radius: 4px;
      }
    }
  }
  .ts_text {
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      width: 26px;
      height: 24px;
      margin-right: 5px;
    }
  }
  .other {
    color: #111111;
    margin-top: 20px;
  }
  .weixin {
    text-align: center;
    margin-top: 20px;
    img:hover {
      cursor: pointer;
    }
    img {
      width: 63px;
      height: 63px;
    }
  }
}
</style>
