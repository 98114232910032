<template>
  <div class="">
    <el-dialog
      :visible.sync="dialogVisible"
      width="550px"
      :show-close="false"
      :modal-append-to-body="false"
      :lock-scroll="false"
    >
      <div class="find">
        <span class="closeicon" @click="closebox()">
          <i class="el-icon-error"></i>
        </span>
        <div class="tab-list font16">
          <div :class="boxnum == 1 ? 'tl-active' : ''" @click="changebox(1)">
            手机找回
          </div>
          <div :class="boxnum == 2 ? 'tl-active' : ''" @click="changebox(2)">
            邮箱找回
          </div>
        </div>
        <!-- 手机找回 -->
        <div class="shouji" v-show="boxnum == 1">
          <el-form
            ref="phoneForm"
            :model="sjregisterform"
            :rules="sjrules"
            class="from-box1"
          >
            <el-form-item prop="phone">
              <el-input
                type="text"
                maxlength="11"
                placeholder="请输入手机号码"
                v-model="sjregisterform.phone"
              />
            </el-form-item>
            <el-form-item prop="code">
              <div class="verify-wrapper" style="display: flex">
                <el-input
                  type="text"
                  maxlength="6"
                  placeholder="请输入短信验证码"
                  v-model="sjregisterform.code"
                ></el-input>
                <el-button
                  class="btn-orange"
                  :disabled="sjdisabled"
                  @click="getCodephone"
                  >{{ sjvaliBtn }}</el-button
                >
              </div>
            </el-form-item>
            <el-form-item prop="password1"
              ><el-input
                type="password"
                maxlength="20"
                placeholder="请设置6至20位登录密码"
                v-model="sjregisterform.password1"
            /></el-form-item>
            <el-form-item prop="password2"
              ><el-input
                type="password"
                maxlength="20"
                placeholder="请确认登录密码"
                v-model="sjregisterform.password2"
            /></el-form-item>
            <el-form-item
              ><button class="d-btn-orange" @click.prevent="submit('phone')">
                确定
              </button></el-form-item
            >
            <div class="zhuyi font14">
              注：如果你无法正常获取邮箱验证码，建议通过手机找回密码，或拨打客
              服热线 0571-85109006 咨询找回。
            </div>
          </el-form>
        </div>
        <!-- 邮箱找回 -->
        <div class="email" v-show="boxnum == 2">
          <el-form
            ref="emailForm"
            :model="emailregisterform"
            :rules="emailrules"
            class="from-box1"
          >
            <el-form-item prop="email"
              ><el-input
                type="text"
                placeholder="请输入邮箱"
                v-model="emailregisterform.email"
            /></el-form-item>
            <el-form-item prop="code">
              <div class="verify-wrapper" style="display: flex">
                <el-input
                  type="text"
                  maxlength="6"
                  placeholder="请输入邮箱验证码"
                  v-model="emailregisterform.code"
                ></el-input>
                <el-button
                  class="btn-orange"
                  :disabled="emaildisabled"
                  @click="getCodeemail"
                  >{{ emailvaliBtn }}</el-button
                >
              </div>
            </el-form-item>
            <el-form-item prop="password1"
              ><el-input
                type="password"
                maxlength="20"
                placeholder="请设置6至20位登录密码"
                v-model="emailregisterform.password1"
            /></el-form-item>
            <el-form-item prop="password2"
              ><el-input
                type="password"
                maxlength="20"
                placeholder="请确认登录密码"
                v-model="emailregisterform.password2"
            /></el-form-item>
            <el-form-item
              ><button class="d-btn-orange" @click.prevent="submit('email')">
                确定
              </button></el-form-item
            >
            <div class="zhuyi font14">
              注：如果你无法正常获取邮箱验证码，建议通过手机找回密码，或拨打客
              服热线 0571-85109006 咨询找回。
            </div>
          </el-form>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import Bus from "../../common/bus.js";
export default {
  data() {
    var checkPhone = (rule, value, callback) => {
      const reg =
        /^0?(13[0-9]|15[012356789]|18[0-9]|14[578]|16[6]|17[035768]|19[19])[0-9]{8}$/;
      if (reg.test(value)) {
        return callback();
      }
      callback("请输入合法的手机号");
    };
    var checkPassword1 = (rule, value, callback) => {
      const reg = /^.{6,20}$/;
      if (reg.test(value)) {
        return callback();
      }
      callback("请输入6-20位密码");
    };
    var checkPassword2 = (rule, value, callback) => {
      if (value === this.sjregisterform.password1) {
        return callback();
      }
      callback("与设置密码不符");
    };
    var checkEmail = (rule, value, callback) => {
      const reg =
        /^[A-Za-zd0-9]+([-_.][A-Za-zd]+)*@([A-Za-zd]+[-.])+[A-Za-zd]{2,5}$/;
      // console.log(value);
      if (reg.test(value)) {
        return callback();
      }
      callback("请输入正确格式邮箱");
    };
    var checkEmailrepass = (rule, value, callback) => {
      if (value === this.emailregisterform.password1) {
        return callback();
      }
      callback("与设置密码不符");
    };
    return {
      dialogVisible: false,
      boxnum: 1,
      // 手机
      sjregisterform: {
        phone: "",
        code: "",
        password1: "",
        password2: "",
      },
      sjrules: {
        phone: [{ validator: checkPhone, required: true, trigger: "blur" }],
        code: [{ required: true, message: "请输入验证码", trigger: "blur" }],
        password1: [
          { validator: checkPassword1, required: true, trigger: "blur" },
        ],
        password2: [
          { validator: checkPassword2, required: true, trigger: "blur" },
        ],
      },
      sjvaliBtn: "获取验证码",
      sjdisabled: false,
      // 邮箱
      emailregisterform: {
        email: "",
        code: "",
        password1: "",
        password2: "",
      },
      emailrules: {
        email: [{ validator: checkEmail, required: true, trigger: "blur" }],
        code: [{ required: true, message: "请输入验证码", trigger: "blur" }],
        password1: [
          { validator: checkPassword1, required: true, trigger: "blur" },
        ],
        password2: [
          { validator: checkEmailrepass, required: true, trigger: "blur" },
        ],
      },
      emailvaliBtn: "获取验证码",
      emaildisabled: false,
    };
  },
  mounted() {
    Bus.$on("forgetpsdstuts", (data) => {
      // console.log("接受到", data);
      this.dialogVisible = data;
    });
  },
  methods: {
    changebox(numb) {
      this.boxnum = numb;
    },
    closebox() {
      this.dialogVisible = false;
    },
    //手机获取验证码
    getCodephone() {
      this.$refs["phoneForm"].validateField("phone", (err) => {
        if (err) {
          return;
        } else {
          this.tackBtnPhone(); //验证码倒数60秒
          let fd = {
            phone: this.sjregisterform.phone,
            type: 0,
          };
          this.$api.phonemsg("post", fd).then((res) => {
            if (res.code === 10200) {
              console.log("手机验证码已发送!");
            }
          });
        }
      });
    },
    tackBtnPhone() {
      //验证码倒数60秒
      let time = 60;
      let timer = setInterval(() => {
        if (time == 0) {
          clearInterval(timer);
          this.sjvaliBtn = "获取验证码";
          this.sjdisabled = false;
        } else {
          this.sjdisabled = true;
          this.sjvaliBtn = time + "秒后重发";
          time--;
        }
      }, 1000);
    },
    //邮箱获取验证码
    getCodeemail() {
      this.$refs["emailForm"].validateField("email", (err) => {
        if (err) {
          return;
        } else {
          this.tackBtnEmail(); //验证码倒数60秒
          let fd = {
            loginEmail: this.emailregisterform.email,
          };
          this.$api.emailmsg("post", fd).then((res) => {
            if (res.code === 10200) {
              console.log("邮箱验证码已发送!");
            }
          });
        }
      });
    },
    tackBtnEmail() {
      //验证码倒数60秒
      let time = 60;
      let timer = setInterval(() => {
        if (time == 0) {
          clearInterval(timer);
          this.emailvaliBtn = "获取验证码";
          this.emaildisabled = false;
        } else {
          this.emaildisabled = true;
          this.emailvaliBtn = time + "秒后重发";
          time--;
        }
      }, 1000);
    },
    submit(type) {
      //确定
      if (type == "phone") {
        this.$refs.phoneForm.validate((valid) => {
          if (valid) {
            let passConfig = {
              code: this.sjregisterform.code,
              password: this.sjregisterform.password1,
              phone: this.sjregisterform.phone,
              registrationId: "",
            };
            this.$api.phoneRepassword("post", passConfig).then((res) => {
              // console.log(res);
              if (res.code == 10200) {
                this.$message({
                  message: "密码修改成功",
                  type: "success",
                });
                this.dialogVisible = false;
              } else {
                this.$message({
                  message: res.message,
                  type: "error",
                });
              }
            });
          } else {
            return false;
          }
        });
      } else {
        this.$refs.emailForm.validate((valid) => {
          if (valid) {
            let passConfig = {
              code: this.emailregisterform.code,
              loginEmail: this.emailregisterform.email,
              password: this.emailregisterform.password1,
              registrationId: "",
            };
            this.$api.emailRepassword("post", passConfig).then((res) => {
              // console.log(res);
              if (res.code != 10200) {
                this.$message({
                  message:
                    res.message == "请重新获取验证码！"
                      ? "请重新获取邮箱验证码！"
                      : res.message,
                  type: "error",
                });
                return;
              }
              this.$message({
                message: "密码修改成功",
                type: "success",
              });
              this.dialogVisible = false;
            });
          } else {
            return false;
          }
        });
      }
    },
  },
};
</script>
<style type="text/css">
.el-dialog__header,
.el-dialog__body {
  padding: 0 !important;
}
</style>
<style lang="less" scoped>
.tab-list {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f3f3f3;
  div:hover {
    cursor: pointer;
  }
  div {
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 1;
    padding: 10px 0;
    color: #111111;
  }
  .tl-active {
    background-color: #ffffff;
    color: #666666;
  }
}
.find {
  position: relative;
}
.closeicon {
  position: absolute;
  top: -40px;
  right: 0;
  font-size: 26px;
  color: #f3f3f3;
}
.shouji,
.email {
  height: 100%;
  // padding: 0 50px;
  padding: 50px 50px 20px 50px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.from-box1 {
  /deep/ .el-input__inner {
    border-radius: 0px;
  }
  /deep/ .el-button {
    border-radius: 0px;
  }

  .btn-orange {
    margin-left: 10px;
  }
  .d-btn-orange {
    width: 100%;
    height: 44px;
    background-color: #00bcff;
    border: none;
    color: #ffffff;
  }
}
.zhuyi {
  color: #666666;
}
</style>
